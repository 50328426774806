<template>
  <div>
    <section class="header p-1" algin="center">
      <center>
        <img
          class="revivify-logo"
          height="50px"
          :src="require('@/assets/images/logo/new_logo.png')"
          alt="Revivify Logo"
        />
      </center>
    </section>
    <div class="aboutus-hero-section">
      <!-- <div class="aboutus-hero-img">
        <img
          :src="require('@/assets/images/banner/aboutus-banner.png')"
          width="100%"
          alt="Revivify Logo"
          class="img-fluid"
        />
      </div> -->
      <h2 class="aboutus-hero-content">ABOUT US</h2>
    </div>
    <b-tabs content-class="mt-3" class="aboutus-tabs p-2">
      <b-tab title="About us" active>
        <div class="row">
          <div class="col-lg-7">
            <h3 class="aboutus-heading">
              INTRODUCING REVIVIFY- The Future of Fashion
            </h3>

            <p class="aboutus-text pt-2">
              Revivify is a luxury resale and refurbish platform specialising in
              authenticated designer wear. Our team has decades of experience
              working with some of the industry's most coveted fashion brands,
              with leading expertise across couture, ready-to-wear, jewellery
              and accessories. Born in 2022 out of the Covid 19 pandemic, our
              innovative platform allows you to increase the lifespan of your
              purchases by transforming or giving them a new home, in turn
              supporting the slow fashion movement. Imagine falling back in love
              with your favourite sari by transforming it into the perfect
              seasonal transition piece. Whether you've got a wedding guest
              outfit you're unlikely to wear again, jewellery pieces that are no
              longer your style or a winter dress that would look great as a
              summer skirt, Revivify means you can truly make the most out of
              your wardrobe. Visit our Sellers Portal to learn more about how
              selling or refurbishing with us can benefit you, others, and the
              planet. With hundreds of exquisite pieces you can purchase direct
              today, refreshing your collection has never been easier. Our
              expert two-step authentication process offers the ultimate peace
              of mind, so you can shop safely as well as sustainably. By
              purchasing with Revivify, you are joining an exciting, necessary
              movement to change the way we shop. With the fashion industry
              responsible for 10% of the globe's emissions, recirculating our
              clothing is not only rewarding but environmentally vital.
              <br /><br />
              Imagine falling back in love with your favourite sari by
              transforming it into the perfect seasonal transition piece.
              Whether you've got a wedding guest outfit you're unlikely to wear
              again, jewellery pieces that are no longer your style or a winter
              dress that would look great as a summer skirt, Revivify means you
              can truly make the most out of your wardrobe.
              <u>Visit our Sellers Portal</u> to learn more about how selling or
              refurbishing with us can benefit you, others, and the planet.
              <br /><br />
              With hundreds of exquisite pieces you can purchase direct today,
              refreshing your collection has never been easier. Our expert
              two-step authentication process offers the ultimate peace of mind,
              so you can shop safely as well as sustainably. By purchasing with
              Revivify, you are joining an exciting, necessary movement to
              change the way we shop. With the fashion industry responsible for
              10% of the globe's emissions, recirculating our clothing is not
              only rewarding but environmentally vital.
            </p>
          </div>
          <div class="offset-lg-1 col-lg-4">
            <h4 class="aboutus-heading">Need Help?</h4>
            <p class="aboutus-text pt-2">
              We're avaiable 24/7 and speak over 14 languages.
            </p>
          </div>
        </div>
      </b-tab>
      <b-tab title="Mission">
        <div class="row">
          <div class="col-lg-7">
            <h3 class="aboutus-heading">
              It all started with one question – how can we inspire change?
            </h3>
            <p class="aboutus-text pt-2">
              At Revivify, our mission is to invert fast fashion and drive
              positive change within the industry. We decided it was time to do
              things differently; to break away from the status quo or "buy and
              bin" mentality, and instead promote a more circular economy. By
              giving your pieces a new home, revamping them or restoring them to
              their former glory, you can help to reduce harmful waste and
              lessen your carbon footprint. By prolonging the life of your
              wardrobe by only nine months can reduce its waste, water and
              carbon footprint by up to 30%. <br /><br />
              With more clothing being manufactured than ever before, it is no
              surprise that the fashion industry is to blame for over 40 million
              tonnes of textile waste every year. Only a minuscule fraction of
              this is recycled or upcycled into new items, which is why doing
              our bit is more important than ever.
            </p>
          </div>
          <div class="offset-lg-1 col-lg-4">
            <h4 class="aboutus-heading">Need Help?</h4>
            <p class="aboutus-text pt-2">
              We're avaiable 24/7 and speak over 14 languages.
            </p>
          </div>
        </div>
      </b-tab>
      <b-tab title="Values">
        <div class="row">
          <div class="col-lg-7">
            <h3 class="aboutus-heading">Sustainability</h3>
            <p class="aboutus-text pt-2 mb-3">
              In addition to aligning with the ethos of slow fashion, we are
              mindful to make sustainable choices across our business. From
              using eco-conscious packaging to taking steps to offset our own
              carbon footprint, we're doing all that we can to lessen our impact
              on the environment.
            </p>
            <h3 class="aboutus-heading">Responsible Business</h3>
            <p class="aboutus-text pt-2 mb-3">
              We are committed to adopting responsible practices in every
              cornerstone of our business. These include upholding our values of
              fairness, inclusion and operating with the utmost integrity. We
              recognize the trust our customers place in us, which is why we use
              a rigorous, 2-step authentication process. Not to mention, in line
              with our dedication to reducing fashion's carbon footprint, every
              step of your purchase is thoughtfully considered, from leaving its
              seller to its arrival at your door. <br /><br />
              We are also passionate about driving behaviour change in the
              fashion industry, which not only means working to reframe
              connotations around 'second hand' pieces but cooperating with
              designers to encourage them to resell their leftover stock, rather
              than destroying it.
            </p>
            <h3 class="aboutus-heading">Premium Service</h3>
            <p class="aboutus-text pt-2">
              We have designed Revivify to be a seamless and enjoyable shopping
              and selling experience. Our friendly customer service team will be
              delighted to answer any questions you might have about your item
              or purchase, and we are always open to any feedback as to how we
              can make things better.
            </p>
          </div>
          <div class="offset-lg-1 col-lg-4">
            <h4 class="aboutus-heading">Need Help?</h4>
            <p class="aboutus-text pt-2">
              We're avaiable 24/7 and speak over 14 languages.
            </p>
          </div>
        </div>
      </b-tab>
      <b-tab title="Community">
        <div class="row">
          <div class="col-lg-7">
            <p class="aboutus-text">
              Giving back to the community is at the core of everything we do. From our partnership with Tree Nation, to making it possible to donate the percentage of your sale to our partner NGOs, we believe in caring for one another as well as the planet. <br><br>
              We are also incredibly proud of our longstanding relationships with not only a number of designers and businesses, but also our loyal customers who return to us time and time again to sell and refurbish their pieces.  <br><br>
              You are our community, and together we can make the fashion revolution a reality. We are always open to feedback on your luxury pre-loved shopping experience with us, how we can do better, and the small things we can continue doing collectively to make a big difference. <br><br>
              At Revivify, we envision we will play just one part in your journey to adopting a more sustainable lifestyle. For this reason, we have curated the following list of other inspiring resources to help you along the way.
            </p>
            <a href="https://www.harpersbazaar.com/uk/fashion/what-to-wear/a41158/how-to-be-sustainable-fashion/">10 simple steps to being more sustainable</a><br><br>
            <a href="https://www.vogue.co.uk/fashion/article/amber-valletta-eco-friendly-life">10 ways to live an eco-friendly life by Amber Valletta </a><br><br>
            <a href="https://www.townandcountrymag.com/style/fashion-trends/g36120168/best-sustainable-brands/">The 40 best sustainable brands helping the planet </a><br><br>
            <a href="https://www.refinery29.com/en-us/green-environmental-buying-spending">Sustainable spending – here's what you need to know</a><br><br>
            <a href="https://www.ft.com/content/a2b007a0-906c-48b1-9651-d9794366c08d">How to spend it...wisely: the green issue</a>
          </div>
          <div class="offset-lg-1 col-lg-4">
            <h4 class="aboutus-heading">Need Help?</h4>
            <p class="aboutus-text pt-2">
              We're avaiable 24/7 and speak over 14 languages.
            </p>
          </div>
        </div>
      </b-tab>
      <b-tab title="Others">
        <div class="row">
          <div class="col-lg-7">
            <p class="aboutus-text">
              We are always on the lookout for talented individuals to join the Revivify team. If your experience aligns with our mission and you are passionate about fashion and sustainability, we would love to hear from you. Keep an eye on this page for our upcoming vacancies or send your CV to us for future consideration. 
            </p>
          </div>
          <div class="offset-lg-1 col-lg-4">
            <h4 class="aboutus-heading">Need Help?</h4>
            <p class="aboutus-text pt-2">
              We're avaiable 24/7 and speak over 14 languages.
            </p>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";

export default {
   metaInfo: {
    title: 'About Us | Revivify',
    htmlAttrs: {
      lang: 'en-US'
    },
  },
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
  },
};
</script>

<style>
.aboutus-tabs .nav-tabs {
  border-bottom: 1px solid lightgrey;
  padding-bottom: 1rem;
}
.aboutus-tabs .nav-item a {
  color: #000;
  font-size: 16px;
}
.aboutus-heading {
  color: #000;
  font-weight: 600;
  padding-bottom: 15px;
}
.aboutus-text {
  font-size: 16px;
  line-height: 25px;
  text-align: justify;
  color: #000 !important;
}
.aboutus-hero-section {
  background: #000;
  position: relative;
  width: 100%;
  height: 150px
}
.aboutus-hero-img {
  opacity: 0.7;
}
.aboutus-hero-content {
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  width: 100%;
  text-align: center;
}
.nav-tabs .nav-link:after{
  background: #000 !important;
  height: 1px !important;
}
.nav-tabs .nav-link {
  padding: 0;
  margin: 0.2rem 1rem !important;
}
.nav-tabs .nav-link.active {
  color: #000;
}

</style>